<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/member/dealer/user/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">添加分销商</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form style="max-width: 750px;" :model="ruleForm" :rules="rules" size="small" ref="ruleForm"
				label-width="160px">
				<el-form-item label="选择用户" prop="nickName">
					<el-input disabled v-model="ruleForm.nickName" placeholder="请选择用户">
						<template slot="append">
							<el-button class="button-item" @click.native="userListVisible = true" size="small">选择用户
							</el-button>
						</template>
					</el-input>
					<div flex="dif:left" style="flex-wrap:wrap;margin-top:20px">
						<div v-if="ruleForm.user_id > 0" @mouseenter="enter()" @mouseleave="leave"
							style="margin-right: 20px;position: relative;cursor: pointer;">
							<app-image mode="aspectFill" width="50px" height='50px' :src="user.avatarUrl">
							</app-image>
							<el-button class="del-btn" v-if="user_status == 1" size="mini" type="danger"
								icon="el-icon-close" circle @click="userDestroy()"></el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="姓名" prop="real_name">
					<el-input v-model="ruleForm.real_name"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model="ruleForm.mobile"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="ruleForm.remark"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button class="button-item" :loading="btnLoading" type="primary" @click="submit('ruleForm')"
						size="small">提交
					</el-button>
				</el-form-item>
			</el-form>
			<el-dialog title="添加用户" :visible.sync="userListVisible" append-to-body>
				<el-form @submit.native.prevent>
					<el-form-item>
						<el-input size="small" v-model="keyword" autocomplete="off" placeholder="用户昵称" style="width: 40%"></el-input>
						<el-button size="small" :loading="btnLoading" @click="search()">查找用户</el-button>
					</el-form-item>
				</el-form>
				<el-table :data="user_list.data" style="width: 100%">
					<el-table-column prop="user_id" label="用户ID" width="80"></el-table-column>
					<el-table-column label="头像" width="280">
						<template slot-scope="scope">
							<app-image mode="aspectFill" style="float: left;margin-right: 8px"
								:src="scope.row.avatarUrl"></app-image>
							<div flex="dir:left cross:center">{{scope.row.nickName}}</div>
							<img class="platform-img" src="statics/img/store/wx.png" alt="">
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button v-if="ruleForm.user_id != scope.row.user_id" plain size="mini" type="primary"
								@click="userAdd(scope.$index, scope.row)">选择
							</el-button>
							<el-button v-else plain size="mini" type="primary" disabled>已选择</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div slot="footer" class="dialog-footer">
					<el-pagination v-if="user_list" background @current-change="pageChange" layout="prev, pager, next"
						:page-size="20" :total="user_list.total_count">
					</el-pagination>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				user_list: [],
				userListVisible: false,
				keyword: "",
				cityList: [],
				ruleForm: {
					user_id: 0,
					nickName: "",
					real_name: '',
					remark: '',
					mobile: '',
				},
				rules: {
					nickName: [{
						required: true,
						message: '请选择用户',
						trigger: 'change'
					}, ],
					real_name: [{
							required: true,
							message: '请输入姓名',
							trigger: 'change'
						},
						{
							max: 120,
							message: '最多输入120个字符',
							trigger: 'change'
						},
					],
					mobile: [{
							required: true,
							message: '请输入手机号',
							trigger: 'change'
						},
						{
							max: 11,
							message: '最多输入11个字符',
							trigger: 'change'
						},
					],
				},
				btnLoading: false,
				cardLoading: false,
				user_status: 0,
				user: {}
			}
		},
		created() {},
		methods: {
			userDestroy() {
				this.user = {};
				this.ruleForm.user_id = 0;
				this.ruleForm.nickName = "";
			},
			userAdd(index, row) {
				this.ruleForm.user_id = row.user_id;
				this.ruleForm.nickName = row.nickName;
				this.user = row;
				this.userListVisible = false;
			},
			enter() {
				this.user_status = 1;
			},
			leave() {
				this.user_status = 0;
			},
			// 点击获取商品列表
			search(page = 1) {
				this.btnLoading = true;
				this.axios({
					token: true,
					method: 'get',
					params: {
						s: 'store/user/index',
						keyword: this.keyword,
						page: page,
					},
				}).then(res => {
					this.btnLoading = false;
					if (res.data.code == 1) {
						this.user_list = res.data.data;
					}
				}).catch(res => {
					this.btnLoading = false;
				});
			},
			//分页
			pageChange(page) {
				this.search(page);
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/member.dealer.user/add',
							},
							data: {
								dealer: this.ruleForm
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'member.dealer.user/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
